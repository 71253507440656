import React from "react"
import { graphql } from "gatsby"
import VideoPage from "../../components/VideoPage"

const VideoStatic = props => {
  const data = props.data.video
  const videoId = data.video_id
  const video = {
    id: data.video_id,
    source: data.video_source,
    audio: data.video_audio,
    subtitles: data.video_subtitles,
    url: data.video_url,
    type: data.video_type,
    title: data.video_title,
    taken_down: data.video_taken_down,
  }
  const movieTranslation = {
    id: data.translation_id,
    slug: data.translation_slug,
    title: data.translation_title,
    synopsis: data.translation_synopsis,
  }
  return (
    <VideoPage videoId={videoId} video={[video]} movieTranslation={movieTranslation} related recommendationNumber={40}/>
  )
}

export default VideoStatic


export const query = graphql`
  query($id: String) {
    video(id: { eq: $id }) {
      id
      video_id
      movie_id
      video_source
      video_audio
      video_subtitles
      video_url
      video_type
      video_title
      video_taken_down
      translation_id
      translation_slug
      translation_title
      translation_synopsis
    }
  }
`

  
  